import React, { useCallback } from 'react'

import { Box, Grid } from '@mui/material'
import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import Callbacks from './callbacks'

const OrderList = props => {
  return (
    <Box className='safearea'>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={11}>
          <SearchFrame
            formSearchName='order_search_review'
            model_name='order'
            populate={[
              'service.contract.company',
              'service.contract.formula',
              'do.commercial_formula',
              'do.customerinfo',
              'assigned_transport.vehicle',
              'rating',
            ]}
            sort={['requestedAt DESC']}
            allowCreate={false}
          >
            <SearchForm
              submitOnMount
              filterToForm={useCallback(Callbacks.filterToFormHandler(), [])}
              formToFilter={useCallback(Callbacks.formToFilterHandler(), [])}
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable tableProps={{ onRowClick: null, placementPagination: 'top' }}>
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
            </SearchTable>
          </SearchFrame>
        </Grid>
      </Grid>
    </Box>
  )

}

export default React.memo(OrderList)
