import FormatUtils from '@front/squirtle/utils/format'

const parseStatus = (record) => {
  const statusName = FormatUtils.getStatus(_.pick(_.get(record, 'auth'), ['active', 'deleted', 'suspendedUntil', 'suspendedSince']))
  const formattedSuspension = FormatUtils.formatSuspended(
    { name: statusName, ..._.pick(_.get(record, 'auth'), ['suspendedSince', 'suspendedUntil', 'motive']) },
    'staff'
  )
  return formattedSuspension
}


const Aux = {
  parseStatus
}

export default Aux
