import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

import { Loader } from '@front/squirtle'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const isReady = hooks.useFormStatus('isReady')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [isReadOnly] = hooks.useFormState('isReadOnly')
  const [, { openDialog }] = hooks.useDialogs()
  const [userModify] = hooks.useModelFunction('user', 'modify')

  const { user_id } = hooks.useParams()

  const onConfirmPassword = useCallback(Callbacks.onConfirmPasswordHandler(userModify, user_id), [userModify, user_id])

  const mergedProps = {
    isManagingRecord,
    isReadOnly,
    openDialog,
    onConfirmPassword
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} {...props} />
    </Loader>
  )
}

export default withContainer
