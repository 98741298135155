import FormatUtils from '@front/squirtle/utils/format'
class Callbacks {
  static filterToFormHandler(CODE_INTERNATIONAL) {
    return function filterToForm(filter) {
      if (_.get(filter, 'auth.gsm'))
        _.merge(filter, {
          auth: {
            gsm: FormatUtils.parsePhoneNumber(_.get(filter, 'auth.gsm'), CODE_INTERNATIONAL)
          }
        })
      return {
        status: ['active', 'deleted', 'suspended'],
        ...filter,
      }
    }
  }
  static formToFilterHandler(CODE_INTERNATIONAL) {
    return function formToFilter(values, extra, meta, state) {
      if (_.has(values, 'auth.gsm'))
        _.merge(values, { auth: { gsm: FormatUtils.parsePhoneNumber(values.auth.gsm, CODE_INTERNATIONAL) } })
      if (_.has(values, 'customerinfo.name'))
        _.merge(values, { customerinfo: { name: { contains: values?.customerinfo?.name } } })
      if (_.has(values, 'info.last_name'))
        _.merge(values, { info: { last_name: { startsWith: values?.info?.last_name } } })
      if (_.has(values, 'info.first_name'))
        _.merge(values, { info: { first_name: { startsWith: values?.info?.first_name } } })
      if (_.has(values, 'auth.email'))
        _.merge(values, { auth: { email: { contains: values?.auth?.email } } })
      return {
        ...values,
        customerinfo: { '!': null },
        status: _.map(values?.status, name => ({ name })),
      }
    }
  }
}

export default Callbacks
