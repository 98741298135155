import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'

import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell>
    <TextHeader textAlign='center' label={I18n.t('order.id.alter')} />
  </TableCell>
  <TableCell>
    <TextHeader textAlign='center' label={I18n.t('user.label', { count: 1 })} />
  </TableCell>
  <TableCell>
    <TextHeader textAlign='center' label={I18n.t('company.label', { count: 1 })} />
  </TableCell>
  <TableCell>
    <TextHeader textAlign='center' label={I18n.t('commercial_formula.label', { count: 1 })} />
  </TableCell>
  <TableCell>
    <TextHeader textAlign='center' label={I18n.t('driver.label', { count: 1 })} />
  </TableCell>
  <TableCell>
    <TextHeader textAlign='center' label={I18n.t('vehicle.label', { count: 1 })} />
  </TableCell>
  <TableCell>
    <TextHeader textAlign='center' label={I18n.t('evaluation.note', { count: 1 })} />
  </TableCell>
  <TableCell>
    <TextHeader textAlign='center' label={I18n.t('comment.order_client', { count: 1 })} />
  </TableCell>
</>

export default React.memo(Header)
